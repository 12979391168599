@font-face {
  font-family: "Lato";
  src: local("Lato"), url(./fonts/Lato/Lato-Light.ttf) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Lato Regular";
  src: local("Lato"), url(./fonts/Lato/Lato-Regular.ttf) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
